import React from 'react';
import RouteForApproval from '@assets/images/RouteForApproval.png';
import { ReactComponent as StreamWorkSVG } from '@assets/icons/streamwork.svg';
import { ReactComponent as RevisitOnboardingSVG } from '@assets/icons/revisit-onboarding.svg';

import { NavigateFunction } from 'react-router-dom';
import { AssetItemDto, AssetVersionItemDto, CampaignItemDto } from '@api/Api';
import { toggleApprovalModal } from '@redux/actions/modalAction';
import { updateAssetSelectedVersion } from '@redux/actions/mediaViewerAction';
import { createTaskBoard } from '@redux/actions/tasks';

export function firstEnterSteps() {
  return [
    {
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div
            className="app-tour-assist-wrapper__image"
            style={{ textAlign: 'center' }}
          >
            <StreamWorkSVG />
          </div>

          <div
            className="app-tour-assist-wrapper__title"
            style={{ textAlign: 'center' }}
          >
            <h2>Welcome to your StreamWork dashboard!</h2>
          </div>
        </div>
      )
    },
    {
      selector: '.workspaces-list-container',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <div className="app-tour-assist-wrapper__title">
              <h2>Workspaces</h2>
            </div>
            <div className="app-tour-assist-wrapper__description">
              <p>Create and manage workspaces here.</p>
            </div>
          </div>
        </div>
      )
    },
    {
      selector: '.heade_container__btn',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <div className="app-tour-assist-wrapper__title">
              <h2>Upload creative media</h2>
            </div>
            <div className="app-tour-assist-wrapper__description">
              <p>
                Upload your first media, or drag & drop into StreamWork to start
                collaborating.
              </p>
            </div>
          </div>
        </div>
      )
    },
    {
      selector: '.campaigns-menu-item',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <div className="app-tour-assist-wrapper__title">
              <h2>Create projects</h2>
            </div>
            <div className="app-tour-assist-wrapper__description">
              <p>
                Organize creative media you’ve uploaded into projects. Check out
                our demo project to see how it works!
              </p>
            </div>
          </div>
        </div>
      )
    }
  ];
}

export function revisitOnboardingSteps() {
  return [
    {
      selector: '.heade_container__help',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div
            className="app-tour-assist-wrapper__title"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 16,
              width: 'unset'
            }}
          >
            <RevisitOnboardingSVG />
            <h2>Revisit onboarding anytime here</h2>
          </div>
        </div>
      )
    }
  ];
}

export function createCampaignSteps() {
  return [
    {
      selector: '#CAMPAIGN_NAME',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Project name</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>
              This is the project you just created. <br /> You can organize
              creative media per project.
            </p>
          </div>
        </div>
      )
    },
    {
      selector: '.ant-tabs-nav-list',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Creative workflows</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>
              Move between tabs to access the core features of StreamWork: media
              collaboration, automated approvals, and task management.
            </p>
          </div>
        </div>
      )
    },

    {
      selector: '#CAMPAIGN_INFO_BUTTON',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Manage project info</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>Add a creative brief, manage members and more.</p>
          </div>
        </div>
      )
    }
  ];
}

export function uploadAssetSteps({
  navigate,
  onClose,
  onCloseProcess
}: {
  navigate: NavigateFunction;
  onClose: (hideCongrats?: boolean) => void;
  onCloseProcess: () => void;
}) {
  return [
    {
      selector: '.asset_card_wr',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>
              Click on the media to collaborate on it with your team, clients or
              vendors.
            </h2>
          </div>
        </div>
      ),
      action: (node: any) => {
        if (!node) return;
        node.classList.add('click_asset_card_wr');
        node.onclick = () => {
          onClose(true);
          onCloseProcess();
          const id = node.getAttribute('data-id');
          navigate(
            `/media-viewer/${id}/${id}/?startTour=create-task-from-comment`
          );
        };
      },
      stepInteraction: true
    }
  ];
}

export function createTaskSteps({
  dispatch,
  selectedCampaign,
  setGoToStep,
  boardsLength
}: {
  dispatch: any;
  boardsLength: number;
  selectedCampaign: CampaignItemDto;
  setGoToStep: any;
}) {
  return [
    {
      selector: '.add-group-button',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Click to create task group</h2>
          </div>
        </div>
      ),
      stepInteraction: true,
      action: (node: any) => {
        if (!node) return;

        node.onclick = (e: any) => {
          e.stopPropagation();
          if (boardsLength <= 1)
            dispatch(
              createTaskBoard({
                campaignId: selectedCampaign.id,
                name: 'Name task group'
              })
            );
          setTimeout(() => {
            setGoToStep(1);
          }, 1);
        };
      }
    },
    {
      selector: '.task-group-name',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Edit task group name</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>
              Name task group after deliverables or creative media you are
              working on in the project.
            </p>
          </div>
        </div>
      )
    },
    {
      selector: '#START-CREATE-TASK-BTN',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Click to create a task</h2>
          </div>
        </div>
      ),
      stepInteraction: true
    },
    {
      selector: '#TASK-NAME-INPUT',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Add a task name</h2>
          </div>
        </div>
      )
    },
    {
      selector: '#SELECT-TASK-CAMPAIGN-INPUT',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Select project</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>Assign this task to a specific project.</p>
          </div>
        </div>
      )
    },
    {
      selector: '#SELECT-TASK-ASSET-INPUT',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Select a media</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>
              Assign a media to this task to make it clear what you&apos;re
              working on.
            </p>
          </div>
        </div>
      )
    }
  ];
}

export function createTaskFromCommentSteps() {
  return [
    {
      selector: '#VERSION_STUCK_BTN',
      position: 'bottom',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Manage version stack</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>
              Switch between new and old versions to see how feedback evolves
              overtime.
            </p>
          </div>
        </div>
      )
    },
    {
      selector: '.new-version-btn',
      position: 'bottom',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Upload the latest version anytime to keep things organized.</h2>
          </div>
        </div>
      )
    },
    {
      selector: '.comment_editor_container',
      position: 'bottom',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Add your comment here</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>Start typing to add a comment to a media.</p>
          </div>
        </div>
      )
    },
    {
      selector: '.shapes_drawing_tools_container',
      position: 'bottom',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Tool bar</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>Use tools to draw directly on creative media.</p>
          </div>
        </div>
      )
    },
    {
      selector: '.media_viewer_comment_task',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Add as a task</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>
              Turn this comment into a task, then assign to a team member to
              take action.
            </p>
          </div>
        </div>
      )
    },
    {
      selector: '#APPROVAL_WORKFLOW_BTN',
      position: 'bottom',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Route for approval</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>Designate reviewers, assign deadlines and watch it go.</p>
          </div>
          <div className="app-tour-assist-wrapper__image">
            <img src={RouteForApproval} alt="#" />
          </div>
        </div>
      )
    }
  ];
}

export function routeForApprovalSteps({
  dispatch,
  setGoToStep,
  selectedVersion,
  assets
}: {
  dispatch: any;
  setGoToStep: any;
  selectedVersion: AssetVersionItemDto;
  assets: AssetItemDto;
}) {
  return [
    {
      selector: '#APPROVAL_WORKFLOW_BTN',
      position: 'bottom',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Route for approval</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>Designate reviewers, assign deadlines and watch it go.</p>
          </div>
          <div className="app-tour-assist-wrapper__image">
            <img src={RouteForApproval} alt="#" />
          </div>
        </div>
      ),
      stepInteraction: true,
      action: (node: any) => {
        if (!node) return;
        node.onclick = () => {
          dispatch(
            toggleApprovalModal({
              visible: true,
              assetVersions: [selectedVersion],
              isLastVersion: true,
              assetIds: [assets.id],
              campaignId: assets.campaignId,
              allowCreate: !!assets.asset?.permissions.createApprovalWorkflows,
              callback: (asset) => {
                const variant = asset.asset?.versions.find(
                  (el) => el.id === selectedVersion.id
                );
                if (!variant) return;
                dispatch(
                  updateAssetSelectedVersion({ newSelectedVersion: variant })
                );
              }
            })
          );
          setTimeout(() => {
            setGoToStep(1);
          }, 500);
        };
      }
    },
    {
      selector: '#AW_STAGE_NAME',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Add a stage name</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>
              Identify the department or stakeholders you plan to route the
              media to first. Ex. &quot;Manager Review&quot;
            </p>
          </div>
        </div>
      )
    },
    {
      selector: '#AW_STAGE_COMPLETE_WHEN',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Set stage completion</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>
              Choose which reviewer or reviewers have ultimate approval to
              finalize this stage.
            </p>
          </div>
        </div>
      )
    },
    {
      selector: '#AW_STAGE_DEADLINE',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Set a deadline</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>Set a due date and time for approvals to be submitted.</p>
          </div>
        </div>
      )
    },
    {
      selector: '#AW_STAGE_REVIEWERS',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Invite reviewers to the approval stage</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>
              Reviewers can be internal or external, they do not need a
              StreamWork account.
            </p>
          </div>
        </div>
      )
    },
    {
      selector: '#AW_STAGE_SETTINGS',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Approval settings</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>Here you can specify granular settings for approval routing.</p>
          </div>
        </div>
      )
    },
    {
      selector: '#AW_STAGE_ROUTE',
      content: () => (
        <div className="app-tour-assist-wrapper">
          <div className="app-tour-assist-wrapper__title">
            <h2>Route for approval</h2>
          </div>
          <div className="app-tour-assist-wrapper__description">
            <p>
              We will automatically send links to reviewers. You can edit, pause
              or delete approval routing anytime.
            </p>
          </div>
        </div>
      )
    }
  ];
}
