import React from 'react';
import { ReactComponent as CampaignIcon } from '@assets/icons/Onboarding/campaign.svg';
import { ReactComponent as TaskIcon } from '@assets/icons/Onboarding/task.svg';
import { ReactComponent as AssetIcon } from '@assets/icons/Onboarding/asset.svg';
import { ReactComponent as CollaborateIcon } from '@assets/icons/Onboarding/collaborate.svg';
import { ReactComponent as AWDefaultSvg } from '@assets/icons/AWButtonStatuses/default.svg';

export const StartOnboardingSteps = [
  {
    type: 'create-campaign',
    title: 'Create your first project',
    description:
      'Name your project, choose who has access, and start collaborating.',
    button: 'Create project',
    icon: <CampaignIcon className="campaign-icon" />
  },
  {
    type: 'upload-asset',
    title: 'Upload media',
    description:
      'Upload videos, images, PDFs, PowerPoints, GIFs, and much more.',
    button: 'Upload media',
    icon: <AssetIcon className="asset-icon" />
  },
  {
    type: 'create-task-from-comment',
    title: 'Collaborate on a media',
    description:
      'Collect feedback on creative media from your internal team, clients or vendors.',
    button: 'Collaborate',
    icon: <CollaborateIcon className="collaborate-icon" />
  },
  {
    type: 'create-task',
    title: 'Create a task',
    description: 'Track to-dos, set deadlines and assign tasks to anyone.',
    button: 'Create task',
    icon: <TaskIcon className="task-icon" />
  },
  {
    type: 'route-for-approval',
    title: 'Route for approval',
    description:
      'Easily automate approvals across departments and stakeholders.',
    button: 'Route',
    icon: <AWDefaultSvg className="aw-icon" />
  }
];

export function OnboardingPercentage(isContributor: boolean, types: string[]) {
  const steps = StartOnboardingSteps.filter((it) => {
    if (isContributor)
      return it.type !== 'route-for-approval' && it.type !== 'create-campaign';

    return true;
  });

  const userComplete = types.filter(
    (it) => steps.findIndex((x) => x.type === it) > -1
  ).length;

  return { total: steps.length, completed: userComplete };
}

export function IsOnboardingCompleted(isContributor: boolean, types: string[]) {
  const steps = StartOnboardingSteps.filter((it) => {
    if (isContributor)
      return it.type !== 'route-for-approval' && it.type !== 'create-campaign';

    return true;
  });
  const isComplete =
    types.filter((it) => steps.findIndex((x) => x.type === it) > -1).length ===
    steps.length;
  return isComplete;
}
